import Router from 'next/router'
import { useEffect } from 'react'

const Home = () => {
  useEffect(() => {
    Router.push('/deliveries')
  }, [])

  return null
}

export default Home
